<template>
  <div class="" style="margin-top: 60px">
    <div>
      <div class="ml-2 mb-5">
        <span class="head">Candidates</span>
      </div>
    </div>
    <div class="tw-flex tw-justify-between">
      <v-row>
        <v-col sm="5" md="9">
          <v-menu
              nudge-bottom="20"
              offset-y
              :close-on-content-click="closeFilter"
              v-model="menuData"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                  label
                  color="white"
                  class="filter_chip mt-3"
                  v-bind="attrs"
                  v-on="on"
              >
                <span class="name ml-2 mt-1">Filter by</span>
                <v-icon size="20" color="#000000" class="ml-1"> mdi-chevron-down</v-icon>
              </v-chip>
            </template>
            <v-card
                width="350"
                class="px-6 pb-6 filter_card"
                :loading="showLoader"
            >
              <div class="mt-4">
                <span class="name mt-4">Talent Pool</span>
                <v-combobox chips v-model="searchCriteria.talent_pool"
                            multiple :items="filterPools" >
                  <template v-slot:selection="data">
                    <v-chip color="#FFF2C7" class="text-capitalize">
                      <h3 class="mx-2">{{ data.item }}</h3>
                      <v-icon
                          small
                          @click.prevent="deleteItem(searchCriteria.talent_pool, data)"
                          class="ml-2"
                      >
                        mdi-close
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
                <span class="name mt-1">Status</span>
                <v-combobox multiple chips
                            v-model="searchCriteria.status"
                            :items="filterStatus">
                  <template v-slot:selection="data">
                    <v-chip color="#FFF2C7">
                      <h3 class="mx-2">{{ data.item }}</h3>
                      <v-icon
                          small
                          @click.prevent="deleteItem(searchCriteria.status, data)"
                          class="ml-2"
                      >
                        mdi-close
                      </v-icon>
                    </v-chip>
                  </template>
                </v-combobox>
                <div class="tw-flex">
                  <BaseButton button-text="Cancel All" outlined @click="cancelFilter"></BaseButton>
                  <BaseButton class="ml-2" button-text="Apply" @click="filterSearch"></BaseButton>
                </div>
              </div>
            </v-card>
          </v-menu>
        </v-col>

        <v-col sm="12" cols="12" md="3" class=" tw-order-1 md:tw-order-none ">
          <v-text-field v-model="search" hide-details label="Search" solo/>
        </v-col>
      </v-row>
    </div>
    <loader v-if="loading" :loading="loading"/>
    <div class="card-table" v-if="!loading">
      <v-data-table
          :headers="headers"
          v-model="selected"
          :mobile-breakpoint="0"
          show-select
          :search="search"
          item-key="id"
          :single-select="false"
          height="50vh"
          :hide-default-footer="candidates.length > 0 ? false : true"
          :items="candidates"
          :no-data-text="not_found"
          class="mt-4 mx-1 table"
          fixed-header
      >
        <template #header.name>
          <div class="mt-3">
            <span class="status">CANDIDATES</span>
          </div>
        </template>
        <template #header.role>
          <div class="mt-3">
            <span class="status">ROLES</span>
          </div>
        </template>
<!--        <template #header.evaluation>-->
<!--          <div class="mt-3">-->
<!--            <span class="status">EVALUATION</span>-->
<!--          </div>-->
<!--        </template>-->
<!--        <template #header.talent_pool>-->
<!--          <div class="mt-3">-->
<!--            <span class="status">TALENT POOL</span>-->
<!--          </div>-->
<!--        </template>-->
        <template #header.status>
          <div class="mt-3">
            <span class="status">STATUS</span>
          </div>
        </template>
        <template #item.name="{item}">
          <div class="tw-flex">
            <v-avatar color="#ECF4F7" class="card-avatar" size="40px">
              {{ getName(item.first_name + ' ' + item.last_name) }}
            </v-avatar>
            <span class="name mt-2 ml-1">{{ item.first_name + ' ' + item.last_name }}</span>
          </div>
        </template>
        <template #item.role="{item}">
          <div class="tw-flex">
            <div v-if="item.roles.length > 1">
              <v-menu offset-y max-height="170px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      color="#5D18BE"
                      dark
                      icon
                      class="ml-n3"
                      v-bind="attrs"
                      v-on="on"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                      v-for="(list, index) in item.roles"
                      :key="index"
                      @click="getEvaluation(index, item, list)"
                  >
                    <v-list-item-title class="status text-capitalize">{{ list.job_name || '---' }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
            <span class="name mt-1 text-capitalize">{{ item.role }}</span>
          </div>

        </template>
<!--        <template #item.evaluation="{item}">-->
<!--          <span class="name ml-2 text-capitalize">{{ item.evaluation }}%</span>-->
<!--        </template>-->
<!--        <template #item.talent_pool="{item}">-->
<!--          <span class="name text-capitalize">{{ item.talent_pool }}</span>-->
<!--        </template>-->
        <template #item.status="{item}">
          <span class="status text-capitalize">{{ item.status }}</span>
        </template>
<!--        <template #item.actions="{item}">-->
<!--          <v-menu bottom offset-y left>-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <td class="d-flex justify-end">-->
<!--                <v-btn v-bind="attrs" v-on="on" icon>-->
<!--                  <v-icon color="black">mdi-dots-vertical</v-icon>-->
<!--                </v-btn>-->
<!--              </td>-->
<!--            </template>-->
<!--            <v-list>-->
<!--              <v-list-item>-->
<!--                <span></span>-->
<!--                <v-list-item-title @click="goToInterview(item)">Invite For Interview</v-list-item-title>-->
<!--              </v-list-item>-->
<!--              <v-list-item>-->
<!--                <MoveToTalentPoolModal :item="item">-->
<!--                  <v-list-item-title id="move_to_talent_pool">Move to Talent Pool</v-list-item-title>-->
<!--                </MoveToTalentPoolModal>-->
<!--              </v-list-item>-->
<!--            </v-list>-->
<!--          </v-menu>-->
<!--        </template>-->

      </v-data-table>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/UIComponents/BaseButton";
import {getAllCandidatesForAJob} from "../../../services/api/APIService";
import _ from 'lodash'
import Loader from "../../UIComponents/reusablesIcon/Loader";
export default {
  name: "RecruiterCandidateComponent",
  components: {Loader, BaseButton},
  data() {
    return {
      search: '',
      closeFilter: false,
      menuData: false,
      rowNumber: 0,
      showLoader: false,
      evaluation: [],
      searchCriteria: {
        talent_pool: [],
        status: [],
      },
      searchResults:[],
      selected: [],
      not_found: 'There are no candidates here yet',
      headers: [
        {
          text: "",
          align: "start",
          value: "name"
        },
        {text: "", value: "role",},
        // {text: "", value: "evaluation"},
        // {text: "", value: "talent_pool"},
        {text: "", value: "status"},
        {text: "", value: "actions", align: "end"}
      ],
      candidates: [],
      filterPools : [],
      filterStatus : [],
      loading : false
    }
  },
  methods: {
    async getProfile(){
      await this.$store.dispatch('editCompany/getCompanyProfile', this.$siteName())
    },
    goToInterview(item){
      this.$router.push(
          {
            name:'InterviewScheduleView',
            params:{step:'1'},
            query:{
              jobId:this.$route.params.id,
              id:item.id,
              email:item.email,
              name:item.first_name + ' '+ item.last_name

            }
          })
    },
    getEvaluation(index, item, list) {
      item.evaluation = list.evaluation
      item.status = list.status
      item.role = list.job_name
    },
    getName(item) {
      if (!item) return
      const fullName = item.split(' ');
      const initials = fullName && fullName.shift().charAt(0) + fullName.pop().charAt(0);
      return initials.toUpperCase();
    },
    cancelFilter() {
      this.menuData = false;
      this.populateData(this.searchResults,'api')
    },
    filterSearch() {
      const finalResultsSet = new Set()
      const results = []
      let finalResults = []
      if(this.searchResults.length > this.candidates.length) {
        this.populateData(this.searchResults,'api')
      }
        const criteriaKeys = Object.keys(this.searchCriteria)
        criteriaKeys.forEach(key => {
          this.searchCriteria[key].forEach(cri => {
            results.push(this.candidates.filter(c => c[key] === cri))
          })
        })
      results.map((result)=>{
        finalResultsSet.add(result)
      })
      finalResults = Array.from(finalResultsSet)

      if (finalResults.length === 0){
        this.populateData(this.searchResults,'api')
      }
      else {
        this.populateData(finalResults,"filter")
      }
    },
    deleteItem(data, item) {
      data.splice(data.indexOf(item), 1)
      data = [...data]
    },
    async getAllCandidatesAppliedForJobs() {
      await getAllCandidatesForAJob(this.$siteName())
          .then(res => {
            this.populateData(res.data,'api')
            this.searchResults = res.data
            this.loading = false
          })
          .catch(err => {
            this.$displaySnackbar({message: err.response.data.detail, success: false})
            this.loading = false
          })
    },
    populateData(data,key) {
      let searchResult = []
      if (key === 'api'){
        this.candidates = data.map(item => ({
          ...item,
          evaluation: item.role[0].evaluation,
          status: item.role[0].status,
          role: item.role[0].job_name,
          roles: item.role,

        }))
        this.getTalentPoolAndStatus(data)
      }
      else {
        data.map((arr)=>{
          searchResult.push(...arr)
        })
         searchResult= _.uniq(searchResult)
        this.candidates = searchResult.map(item => ({
          ...item,
          evaluation: item.roles[0].evaluation,
          status: item.roles[0].status,
          role: item.roles[0].job_name,
          roles: item.roles,

        }))
        this.getTalentPoolAndStatus(searchResult)
      }

    },
    getTalentPoolAndStatus(pools){
      const talentPoolSet = new Set();
      const talentStatus = new Set();
      pools.map((pool)=>{
        talentPoolSet.add(pool.talent_pool)
        if (typeof (pool.role) !== 'string'){
          pool.role.map((status)=>{
            talentStatus.add(status.status)
          })
        }
        else {
          pool.roles.map((status)=>{
            talentStatus.add(status.status)
          })
        }
      })
      this.filterPools = Array.from(talentPoolSet)
      this.filterStatus = Array.from(talentStatus)
    }
  },
  async created() {
    await this.getAllCandidatesAppliedForJobs()
    await this.getProfile()

  }
}
</script>

<style scoped>
.head {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 31px;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #142E70;
}

.status {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  color: #1E323F;
}

.name {
  font-family: DM Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 5px;

  color: #1E323F;
}

.card-avatar {
  font-family: DM Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #058EF2;
}

.table >>> ::-webkit-scrollbar {
  width: 3px !important;

}

.table {
  overflow-x: hidden !important;
  cursor: pointer;

}

.table >>> ::-webkit-scrollbar-track {
  box-shadow: white;
  border-radius: 5px;
}

.table >>> ::-webkit-scrollbar-thumb {
  background: #C4C4C4;
  border-radius: 5px;
}
.table >>> .mdi-checkbox-blank-outline::before {
  margin-bottom: 14px !important;
}

</style>
